<template>
  <div class="skin-thickness q-w360 q-fit">
    <question-with-error-wrap :error="getFieldsError(['alreadyPatient'])">
      <single-answer-question
        :title="alreadyPatientFieldTitle"
        :value="alreadyPatient"
        :options="$options.yesNoResponseOptions"
        yes-no
        @input="onFieldChange('alreadyPatient', $event)"
      />
    </question-with-error-wrap>

    <fade-in-transition-wrap>
      <question-with-error-wrap
        v-if="isAssociatedWithClinicVisible"
        class="q-mt24 q-mt32-md"
        :error="getFieldsError(['isAssociatedWithClinic'])"
      >
        <single-answer-question
          title="label.existingPatientClinicLocation"
          :value="isAssociatedWithClinic"
          :options="doctorClinicLocations"
          @input="onFieldChange('isAssociatedWithClinic', $event)"
        />
      </question-with-error-wrap>
    </fade-in-transition-wrap>

    <fade-in-transition-wrap>
      <question-with-error-wrap
        v-if="wantToConnectWithClinicVisible"
        class="q-mt24 q-mt32-md"
        :error="getFieldsError(['wantToConnectWithClinic'])"
      >
        <single-answer-question
          title="label.newPatientClinicLocation"
          :value="wantToConnectWithClinic"
          :options="doctorClinicLocationsWithUnsure"
          @input="onFieldChange('wantToConnectWithClinic', $event)"
        />
      </question-with-error-wrap>
    </fade-in-transition-wrap>
  </div>
</template>

<script>
import { clone } from 'lodash';

import FadeInTransitionWrap from '@/components/wrappers/FadeInTransitionWrap';
import QuestionWithErrorWrap from '@/modules/questionnaire/new-design-components/questions/QuestionWithErrorWrap';
import SingleAnswerQuestion from '@/modules/questionnaire/new-design-components/questions/SingleAnswerQuestion';

import { stepTemplateMixin } from '@/modules/questionnaire/mixins/stepTemplateMixin';

import { YES_NO_RESPONSE_OPTIONS } from '@/modules/questionnaire/constants/questions';

export default {
  name: 'MyDoctorTemplate',
  components: { FadeInTransitionWrap, QuestionWithErrorWrap, SingleAnswerQuestion },
  mixins: [stepTemplateMixin],
  yesNoResponseOptions: YES_NO_RESPONSE_OPTIONS,
  props: {
    alreadyPatient: {
      type: String,
      default: ''
    },
    doctorFullName: {
      type: String,
      default: ''
    },
    isAssociatedWithClinicVisible: {
      type: Boolean,
      default: false
    },
    isAssociatedWithClinic: {
      type: String,
      default: ''
    },
    wantToConnectWithClinicVisible: {
      type: Boolean,
      default: false
    },
    wantToConnectWithClinic: {
      type: String,
      default: ''
    },
    clinicLocations: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    alreadyPatientFieldTitle() {
      return this.$t('label.alreadyPatient', [this.doctorFullName]);
    },
    doctorClinicLocations() {
      return this.clinicLocations.map(({ clinicName }) => ({
        value: clinicName,
        text: `${this.doctorFullName} - ${clinicName}`
      }));
    },
    doctorClinicLocationsWithUnsure() {
      const options = clone(this.doctorClinicLocations);

      options.push({ value: 'unsure', text: this.$t('clinicLocation.unsure') });

      return options;
    }
  }
};
</script>
